import React, { Dispatch, SetStateAction } from 'react';
import { Checkbox, IconButton, Stack } from '@mui/material';
import Tooltip from '@mui/material/Tooltip';
import BorderColorTwoToneIcon from '@mui/icons-material/BorderColorTwoTone';
import PersonOutlineIcon from '@mui/icons-material/PersonOutline';
import { NavigateFunction } from 'react-router-dom';
import DeleteIcon from '@material-ui/icons/Delete';

export const columnListNameMember = 'Member';
export const columnListNameGroup = 'Group';
export const columnListNameEvent = 'Event';
export const columnListNameInput = 'Input';

const memberColumns = [
    { header: 'Name', accessor: 'name' },
    { header: 'Gender', accessor: 'sex' },
    { header: 'Group', accessor: 'group' },
    { header: 'Member Id', accessor: 'member_id' },
    { header: 'Address', accessor: 'address' },
    { header: 'Contact Number', accessor: 'contact' }
];

const groupColumns = [
    { header: 'Group Id', accessor: 'groupId' },
    { header: 'Group Name', accessor: 'name' },
    { header: 'Group Type', accessor: 'groupType' },
    { header: 'Address', accessor: 'address' },
    { header: 'Formation date', accessor: 'formationDate' },

];

const eventColumns = [
    { header: 'Name of the event', accessor: 'event_label' },
    { header: 'Date', accessor: '' },
    { header: 'Beneficiary Id', accessor: '' },
    { header: 'Number of Beneficiary', accessor: '' },
    { header: 'Union', accessor: '' },
    { header: 'Upazilla', accessor: '' },
    { header: 'District', accessor: '' }
];

const inputColumns = [
    { header: 'Name of the Beneficiary', accessor: '' },
    { header: 'Father/Hus Name', accessor: '' },
    { header: 'Beneficiary Id', accessor: '' },
    { header: 'Block', accessor: '' },
    { header: 'Union', accessor: '' },
    { header: 'Upazilla', accessor: '' },
    { header: 'District', accessor: '' }
];

export const listDefaultData = {
    totalCount: 0,
    page: 1,
    limit: 10,
    data: []
};

export const dataColumns = (
    theme: any,
    component: string,
    editUrl: string,
    profileUrl: string,
    navigate: NavigateFunction,
    setSelectedData: Dispatch<SetStateAction<any>>,
    setIsDeleteModalOpen: Dispatch<SetStateAction<boolean>>,
    projectId: string | undefined,
    deletePermission: boolean,
    handleDelete: (id: string | number) => void,
    handleRowSelection?: (item: any) => void,
    isSelected?: (id: string) => boolean,

) => {
    let columnData;
    switch (component) {
        case columnListNameMember:
            columnData = memberColumns;
            break;
        case columnListNameGroup:
            columnData = groupColumns;
            break;
        case columnListNameEvent:
            columnData = eventColumns;
            break;
        case columnListNameInput:
            columnData = inputColumns;
            break;
        default:
            columnData = memberColumns;
    }

    return [
        ...columnData,
        {
            header: 'Action',
            accessor: 'action',
            content: (item: any) => (
                <Stack direction="row" justifyContent="center" alignItems="center">
                    {component !== columnListNameEvent && component !== columnListNameInput && (
                        <Tooltip placement="top" title="Edit">
                            <IconButton
                                color="primary"
                                sx={{
                                    color: theme.palette.warning.dark,
                                    borderColor: theme.palette.warning.main,
                                    '&:hover ': { background: theme.palette.warning.light }
                                }}
                                size="large"
                                onClick={() => {
                                    navigate(`${editUrl}/${item.id}`);
                                }}
                            >
                                <BorderColorTwoToneIcon sx={{ fontSize: '1.3rem' }} />
                            </IconButton>
                        </Tooltip>
                    )}

                    {component !== columnListNameMember && (
                        <Tooltip placement="top" title="Profile">
                            <IconButton
                                color="primary"
                                sx={{
                                    color: theme.palette.success.dark,
                                    borderColor: theme.palette.success.main,
                                    '&:hover ': { background: theme.palette.success.light }
                                }}
                                size="large"
                                onClick={() => {
                                    navigate(`${profileUrl}/${item.id}`);
                                }}
                            >
                                <PersonOutlineIcon sx={{ fontSize: '1.3rem' }} />
                            </IconButton>
                        </Tooltip>
                    )}
                    {deletePermission && component === columnListNameMember && (
                        <Tooltip placement="top" title="Delete">
                            <IconButton

                                sx={{
                                    color: 'red',
                                    borderColor: theme.palette.success.main,
                                    '&:hover ': { background: theme.palette.success.light }
                                }}
                                size="large"
                                onClick={() => {
                                    handleDelete(item.id);
                                }}
                            >
                                <DeleteIcon />
                            </IconButton>
                        </Tooltip>
                    )}
                    {(component === columnListNameEvent || component === columnListNameInput) && (
                        <Checkbox
                            color="primary"
                            onChange={() => handleRowSelection?.(item)}
                            // Assuming you have some state to manage selected rows
                            checked={isSelected?.(item.id)} // Adjust this according to your selected row state
                        />
                    )}
                    {component === columnListNameMember &&
                        <Tooltip placement="top" title="Profile">
                            <IconButton
                                color="primary"
                                sx={{
                                    color: theme.palette.warning.dark,
                                    borderColor: theme.palette.warning.main,
                                    '&:hover ': { background: theme.palette.warning.light }
                                }}
                                size="large"
                                onClick={() => {
                                    navigate(`/project/${projectId}/sacp/member/profile?instance=${item.id}`);
                                }}
                            >
                                <PersonOutlineIcon sx={{ fontSize: '1.3rem' }} />
                            </IconButton>
                        </Tooltip>
                    }
                    {/* <Tooltip placement="top" title="Delete">
                        <IconButton
                            color="primary"
                            sx={{
                                color: theme.palette.orange.dark,
                                borderColor: theme.palette.orange.main,
                                '&:hover ': { background: theme.palette.orange.light }
                            }}
                            size="large"
                            onClick={() => {
                                setSelectedData(item);
                                setIsDeleteModalOpen(true);
                            }}
                        >
                            <DeleteTwoToneIcon sx={{ fontSize: '1.3rem' }} />
                        </IconButton>
                    </Tooltip> */}

                </Stack>
            )
        }
    ];
};
