import React from 'react';
import { useQuery } from '@tanstack/react-query';
import { getProjectActivityList } from 'services/reactQueryservices';
import { projectActivityKey } from 'react_query/query-keys';

const useGetProjectActivityList = (page: number) => {
    const { data, isFetching, isFetched, isError, error } = useQuery({
        queryKey: [projectActivityKey, page],
        queryFn: () => getProjectActivityList(page)
    })

    return {
        data, isFetching, isFetched, isError, error
    }
}

export default useGetProjectActivityList