import axios from 'axios';
import { StatusCodes } from 'http-status-codes';
import { responseErrorHandler } from 'services/master';
import axiosServices from 'utils/axiosServices';
import {
    createLogframeNodeURL,
    getLogframeDefinitionTreeStructureURL,
    getLogframeDetailURL,
    getLogframeTreeURL,
    getProjectByOrgIdURL,
    getProjectIndicatorListURL,
    getSdgListURL,
    updateLogframeParentURL
} from 'utils/serverUrls';
import { _deserailize } from '../../utils/Deserialize';

const SERVER_URL = 'http://mplanner.mpower-social.com:3001';
const CREATE_LOGFRAME_ACTIVITY_NODE = `${SERVER_URL}/createactivity`;
const CHANGE_LOG_FRAME_PARENT = `${SERVER_URL}/changelogframeparent`;

const token =
    'eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJfaWQiOiJkZXZhc2hpc2hAbXBvd2VyLXNvY2lhbC5jb20iLCJpYXQiOjE2OTg1NTk3NjV9.OHk2mYneXUgwsO9eAl3ynJKSgNTLdRMxsq-1LrX4VLY';

const headerObj = {
    headers: {
        Authorization: `Bearer ${token}`
    }
};

export const getProjectList = async () => {
    try {
        const params = {
            pageSize: 10000000,
            currentPage: 1
        };
        const projectResponse = await axiosServices.post(`${`${getProjectByOrgIdURL}`}`, { ...params });
        const projectData = await _deserailize(projectResponse.data);
        return projectData;
    } catch (ex) {
        console.log('error ::: ', ex);
        return null;
    }
};

export const getSdgList = async () => {
    try {
        const response: any = await axiosServices.get(`${`${getSdgListURL}`}`);
        return response;
    } catch (ex) {
        console.log('error ::: ', ex);
        return null;
    }
};

export const getProjectIndicatorList = async (dataId: any) => {
    try {
        const params = { projectId: dataId };
        const response: any = await axiosServices.get(`${getProjectIndicatorListURL}`, { params });
        return response;
    } catch (ex) {
        console.log('error ::: ', ex);
        return null;
    }
};

export const getProjectLogframeStructure = async (dataId: any) => {
    try {
        const params = { projectId: dataId };
        const response: any = await axiosServices.get(`${getLogframeDefinitionTreeStructureURL}`, { params });
        return response;
    } catch (ex) {
        console.log('error ::: ', ex);
        return null;
    }
};

export const getProjectLogframe = async (dataId: any) => {
    try {
        const params = { projectId: dataId };
        const response: any = await axiosServices.get(`${getLogframeTreeURL}`, { params });
        return response;
    } catch (ex) {
        console.log('error ::: ', ex);
        return null;
    }
};

export const getLogframeDetail = async (dataId: any) => {
    try {
        const params = { logframeId: dataId };
        const response: any = await axiosServices.get(`${getLogframeDetailURL}`, { params });
        return response;
    } catch (ex) {
        console.log('error ::: ', ex);
        return null;
    }
};

export const createLogframe = async (dataId: any, payload: any) => {
    try {
        payload.projectId = dataId;
        return await axiosServices.post(`${createLogframeNodeURL}`, payload);
    } catch (ex) {
        console.log('error ::: ', ex);
        return null;
    }
};

export const updateLogframeParent = async (dataId: any, payload: any) => {
    try {
        payload.logframeId = dataId;
        const response = await axiosServices.post(`${updateLogframeParentURL}`, payload);
        return response;
    } catch (ex) {
        return responseErrorHandler(ex);
    }
};

export const createLogframeActivity = async (dataId: any, payload: any) => {
    try {
        const response: any = await axios.post(`${CREATE_LOGFRAME_ACTIVITY_NODE}/${dataId}`, payload, headerObj);
        return response;
    } catch (ex) {
        console.log('error ::: ', ex);
        return null;
    }
};

export default {};
