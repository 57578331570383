import React, { useState } from 'react';
// material-ui
import { useTheme } from '@mui/material/styles';
import {
    Box,
    Button,
    Grid,
    Tab,
    Tabs,
    Typography,
    Table,
    TableBody,
    TableCell,
    TableContainer,
    TableHead,
    TableRow,
    Paper,
    TablePagination,
    Tooltip,
    IconButton
} from '@mui/material';
import LibraryAddOutlinedIcon from '@mui/icons-material/LibraryAddOutlined';
import Loader from 'ui-component/Loader';
import BorderColorIcon from '@mui/icons-material/BorderColor';
import PersonOutlineIcon from '@mui/icons-material/PersonOutline';

// project imports
import MainCard from 'ui-component/cards/MainCard';
import { gridSpacing } from 'store/constant';
import { useNavigate, useParams } from 'react-router-dom';
import useGetProjectActivityList from 'hooks/consume_api/query/useGetProjectActivityList';
import { ProjectActivityType } from 'types/sacp';
import DeleteTwoToneIcon from '@mui/icons-material/DeleteTwoTone';
import useAuth from 'hooks/useAuth';
import useSnackbar from 'hooks/useSnackbar';
import useDeleteProject from 'hooks/consume_api/mutation/useDeleteProject';
import PromptDialog from 'views/ui-element/PromptDialog';
import BorderColorTwoToneIcon from '@mui/icons-material/BorderColorTwoTone';
import DeleteDeny from './utils/DeleteDeny';

const ProjectActivityMain = () => {
    const { id: projectId } = useParams();
    const submissionUrl = `/project/${projectId}/form/160/538/new-submission`;
    const editUrl = `/project/${projectId}/form/160/538/edit-submission`;
    const profileUrl = `/project/${projectId}/sacp/project-activity/profile`;
    const navigate = useNavigate();
    const theme = useTheme();
    const { user } = useAuth();
    const snackBar = useSnackbar();

    const [open, setOpen] = useState(false);
    const [deleteId, setDeleteId] = React.useState('');

    const [page, setPage] = useState<number>(0);

    const { data, isFetching } = useGetProjectActivityList(page + 1);
    const { mutate } = useDeleteProject();

    const handleAddClick = () => {
        navigate(submissionUrl);
    };

    const handleUpdate = (id: number) => {
        navigate(`${editUrl}/${id}`);
    };

    const handleProfile = (subActivity: string, subActivityId: string, date: string | null, location: string, instance: number, implementedBy: string) => {
        navigate(`${profileUrl}?subactivity=${subActivity}&subactivityId=${subActivityId}&date=${date}&location=${location}&instance=${instance}&implementedby=${implementedBy}`);
    };
    const handleChangePage = (event: React.MouseEvent<HTMLButtonElement> | null, newPage: number) => {
        setPage(newPage);
    };


    const handleDelete = (id: number | string) => {


        let admin = false;
        user?.roles?.map((role) => {
            if (role.name === "FAO_ADMIN") {
                admin = true;
            }
        })
        if (admin) {
            setDeleteId(id.toString());
            setOpen(true);
        }
        else {
            snackBar("Only admin can delete");
        }


    }
    return (
        <>
            {isFetching && <Loader />}
            <MainCard
                contentSX={{ padding: 0 }}
                sx-={{ padding: 0 }}
                title={
                    <Grid container alignItems="center" justifyContent="space-between" spacing={gridSpacing}>
                        <Grid item>
                            <Typography variant="h3">Project Activity List</Typography>
                        </Grid>
                        <Grid item>
                            <Button sx={{ ml: -1 }} color="secondary" variant="contained" onClick={() => handleAddClick()}>
                                <LibraryAddOutlinedIcon fontSize="small" sx={{ mr: 0.75 }} />
                                Add Project Activity
                            </Button>
                        </Grid>
                    </Grid>
                }
            >
                <Grid>
                    <TableContainer component={Paper}>
                        <Table sx={{ width: '100%' }} aria-label="simple table">
                            <TableHead>
                                <TableRow>
                                    <TableCell align="left">#</TableCell>
                                    <TableCell align="left">Name of Sub-Activity</TableCell>
                                    <TableCell align="left">Implemented By</TableCell>
                                    <TableCell align="left">Date</TableCell>
                                    <TableCell align="left">Location</TableCell>
                                    <TableCell align="left">Action</TableCell>
                                </TableRow>
                            </TableHead>
                            <TableBody>
                                {data?.data?.data &&
                                    data?.data?.data.map((item: ProjectActivityType, index: number) => (
                                        <TableRow key={index}>
                                            <TableCell align="left">{((page) * 10) + index + 1}</TableCell>
                                            <TableCell align="left">{item.sub_activity}</TableCell>
                                            <TableCell align="left">{item.implemented_by}</TableCell>
                                            <TableCell align="left">{item.implementation_date}</TableCell>
                                            <TableCell align="left">{item.location}</TableCell>
                                            <TableCell align="left">
                                                <Grid sx={{ display: 'flex' }}>

                                                    <Tooltip placement="top" title="Edit">
                                                        <IconButton
                                                            color="primary"
                                                            sx={{
                                                                color: theme.palette.warning.dark,
                                                                borderColor: theme.palette.warning.main,
                                                                '&:hover ': { background: theme.palette.warning.light }
                                                            }}
                                                            size="large"
                                                            onClick={() => {
                                                                navigate(`${editUrl}/${item.id}`);
                                                            }}
                                                        >
                                                            <BorderColorTwoToneIcon onClick={() => handleUpdate(item.id)} sx={{ cursor: 'pointer' }} />
                                                        </IconButton>
                                                    </Tooltip>

                                                    <Tooltip title="Click to view profile" arrow>
                                                        <PersonOutlineIcon
                                                            onClick={() =>
                                                                handleProfile(
                                                                    item.sub_activity,
                                                                    item.sub_activity_type,
                                                                    item.implementation_date,
                                                                    item.location,
                                                                    item.id,
                                                                    item.implemented_by
                                                                )
                                                            }
                                                            sx={{ marginLeft: '5px', cursor: 'pointer', color: 'success.main', marginTop: "12px" }} // Change 'primary.main' to your desired color
                                                        />
                                                    </Tooltip>
                                                    <Tooltip placement="top" title="Delete">
                                                        <DeleteTwoToneIcon
                                                            onClick={() => handleDelete(item.id)}
                                                            sx={{ fontSize: '1.3rem', marginTop: "13px", marginLeft: "6px", cursor: "pointer", color: "red" }}
                                                        />
                                                    </Tooltip>


                                                </Grid>
                                            </TableCell>
                                        </TableRow>
                                    ))}
                            </TableBody>
                        </Table>
                    </TableContainer>
                    <TablePagination
                        rowsPerPageOptions={[10]}
                        component="div"
                        count={data?.data?.total}
                        page={page}
                        onPageChange={handleChangePage}
                        rowsPerPage={10}
                        sx={{
                            display: 'flex',
                            justifyContent: 'flex-end',
                            alignItems: 'center',
                            '.MuiTablePagination-toolbar': {
                                padding: '0 16px'
                            },
                            '.MuiTablePagination-selectLabel, .MuiTablePagination-input, .MuiTablePagination-actions, .MuiTablePagination-displayedRows': {
                                marginTop: 0,
                                marginBottom: 0,
                                display: 'flex',
                                alignItems: 'center'
                            }
                        }}
                    />
                </Grid>
                <PromptDialog
                    open={open}
                    setOpen={setOpen}
                    agreeAction={() => mutate(deleteId)}
                    dialogTitleText={'Remove participant'}
                    dialogContentText={'This participant will be removed. Are you sure?'}
                    disagreeText={'Cancel'}
                    agreeText={'Remove'}
                />

            </MainCard>
        </>
    );
};

export default ProjectActivityMain;
