import React, { useEffect, useState } from 'react';
import MainCard from 'ui-component/cards/MainCard';
import { Button, CardContent, Grid, Typography } from '@mui/material';
import { gridSpacing } from 'store/constant';
import { GroupType } from 'types/sacp';
import { useNavigate, useParams } from 'react-router-dom';
import LibraryAddOutlinedIcon from '@mui/icons-material/LibraryAddOutlined';
import { useTheme } from '@mui/material/styles';
import ConfirmDeleteModal from 'components/common/ConfirmDeleteModal';
import { columnListNameGroup, dataColumns, listDefaultData } from '../Constant';
import ServerPagingTable from 'components/Table/ServerPagingTable';
import { getGroupList, deleteInstance } from 'services/sacp';
import Loader from 'ui-component/Loader';

const GroupRegister = () => {
    const theme = useTheme();
    const { id: projectId } = useParams();
    const navigate = useNavigate();

    const [isDeleteModalOpen, setIsDeleteModalOpen] = useState(false);
    const [selectedData, setSelectedData] = useState<GroupType | null>(null);
    const [data, setData] = useState(listDefaultData);
    const [isFetching, setIsFetching] = useState<boolean>(false);

    const formId = process.env.REACT_APP_GROUP_FORM_ID;
    const xformId = process.env.REACT_APP_GROUP_XFORM_ID;

    const submissionUrl = `/project/${projectId}/form/${formId}/${xformId}/new-submission`;
    const editUrl = `/project/${projectId}/form/${formId}/${xformId}/edit-submission`;
    const profileUrl = `/project/${projectId}/sacp/group`;

    const fetchData = async (page?: number, limit?: number) => {
        setIsFetching(true);
        const groupData = await getGroupList({ page, limit });
        console.log(groupData, "=========tt===========")
        setIsFetching(false);
        setData(groupData);
    };

    const handleDeleteConfirm = async () => {
        await deleteInstance({ dataId: selectedData?.id });
    };

    const handleAddClick = () => {
        navigate(submissionUrl);
    };

    useEffect(() => {
        fetchData();
    }, []);

    return (
        <MainCard
            content={false}
            contentSX={{ padding: 0 }}
            sx-={{ padding: 0 }}
            title={
                <Grid container alignItems="center" justifyContent="space-between" spacing={gridSpacing}>
                    <Grid item>
                        <Typography variant="h3">Group List</Typography>
                    </Grid>
                    <Grid item>
                        <Button sx={{ ml: -1 }} color="secondary" variant="contained" onClick={() => handleAddClick()}>
                            <LibraryAddOutlinedIcon fontSize="small" sx={{ mr: 0.75 }} />
                            Add New Group
                        </Button>
                    </Grid>
                </Grid>
            }
        >
            {isFetching ? (
                <Loader />
            ) : data?.data.length !== 0 ? (
                <CardContent>
                    <ServerPagingTable
                        data={data?.data}
                        columns={dataColumns(
                            theme,
                            columnListNameGroup,
                            editUrl,
                            profileUrl,
                            navigate,
                            setSelectedData,
                            setIsDeleteModalOpen
                        )}
                        fetchData={fetchData}
                        showSL
                    />
                </CardContent>
            ) : (
                <CardContent>
                    <Typography variant="body1">No Group data found</Typography>
                </CardContent>
            )}

            {isDeleteModalOpen && (
                <ConfirmDeleteModal
                    open={isDeleteModalOpen}
                    setOpen={setIsDeleteModalOpen}
                    subTitle={`Delete Group '${selectedData?.name}'?`}
                    handleConfirm={handleDeleteConfirm}
                />
            )}
        </MainCard>
    );
};

export default GroupRegister;
