import React, { useEffect, useState } from 'react';

// material-ui
import { Table, TableBody, TableCell, TableContainer, TableHead, TableRow, TablePagination, Grid } from '@mui/material';
import Pagination from '@mui/material/Pagination';

import { checkNullInfo } from 'utils/Helpers';

const ServerPagingTable = ({ data, columns, showSL, fetchData, divisionId, districtId, upazilaId, unionId }: any) => {
    const [page, setPage] = useState<number>(0);
    const [count, setCount] = useState<number>(0);
    const [rowsPerPage, setRowsPerPage] = useState<number>(25);
    const [rowsPerPageChange, setRowsPerPageChange] = useState<boolean>(false);

    useEffect(() => {
        setCount(data?.totalCount);
        setPage(data?.page - 1);
        setRowsPerPage(data?.limit);
    }, [data]);

    useEffect(() => {
        if (rowsPerPageChange) {
            setPage(0);
            fetchData(1, rowsPerPage);
            setRowsPerPageChange(false);
        }
    }, [rowsPerPage]);

    const handleChangePage = (_: React.ChangeEvent<unknown>, newPage: number) => {
        setPage(newPage);
        fetchData(newPage + 1, rowsPerPage, divisionId, districtId, upazilaId, unionId);
    };

    const handleChangeRowsPerPage = (event: React.ChangeEvent<HTMLTextAreaElement | HTMLInputElement> | undefined) => {
        setRowsPerPage(+event?.target?.value!);
        setRowsPerPageChange(true);
    };

    const getRowKey = (targetId: string | number) => {
        return data?.data.findIndex((row: any) => row.id === targetId) + 1 + page * rowsPerPage;
    };

    const renderCell = (item: any, column: any) => {
        if (column.content) {
            return column.content(item);
        }
        return checkNullInfo(item[column.accessor]);
    };

    return (
        <>
            <TableContainer>
                <Table>
                    <TableHead>
                        <TableRow>
                            {showSL && <TableCell sx={{ pl: 3 }}>#</TableCell>}
                            {columns.map((column: any, index: number) => (
                                <>
                                    {column.accessor === 'action' ? (
                                        <TableCell align="center" key={index}>
                                            {column.header}
                                        </TableCell>
                                    ) : (
                                        <TableCell key={index}>{column.header}</TableCell>
                                    )}
                                </>
                            ))}
                        </TableRow>
                    </TableHead>
                    <TableBody>
                        {data?.data &&
                            data?.data.map((row: any) => (
                                <TableRow hover key={getRowKey(row.id)}>
                                    {showSL && <TableCell sx={{ pl: 3 }}>{getRowKey(row.id)}</TableCell>}
                                    {columns.map((column: any, index: number) => {
                                        // This will log each column and its index
                                        return <TableCell key={index}>{renderCell(row, column)}</TableCell>;
                                    })}
                                </TableRow>
                            ))}
                    </TableBody>
                </Table>
            </TableContainer>
            <Grid display="flex" justifyContent="end" marginTop="10px">
                <Pagination count={count} color="primary" page={page} onChange={handleChangePage} />
            </Grid>
        </>
    );
};

export default ServerPagingTable;
