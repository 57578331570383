import React from 'react';
import { Link } from 'react-router-dom';

// material-ui
import { useTheme } from '@mui/material/styles';
import { Box, Grid, Tab, Tabs } from '@mui/material';

// project imports
import MainCard from 'ui-component/cards/MainCard';
import { gridSpacing } from 'store/constant';

// assets
import MemberIcon from '@mui/icons-material/VerifiedUser';
import GroupIcon from '@mui/icons-material/Group';
import DashboardTwoToneIcon from '@mui/icons-material/DashboardTwoTone';
import EventIcon from '@mui/icons-material/Event';
import KeyboardIcon from '@mui/icons-material/Keyboard';
import MemberRegister from './Member';
import GroupRegister from './Group';
import Dashboard from "./Dashboard";
import EventRegister from './Event';
import InputRegister from './Input';
import ProjectActivityMain from './ProjectActivity';
import WorkPlanMain from './WorkPlan';
// import ReportingPeriodType from './ReportingPeriodType';

// tabs panel
function TabPanel(props: { children: React.ReactElement; value: number; index: number }) {
    const { children, value, index, ...other } = props;
    return (
        <div role="tabpanel" hidden={value !== index} id={`simple-tabpanel-${index}`}
            aria-labelledby={`simple-tab-${index}`} {...other}>
            {value === index && <Box sx={{ p: 0 }}>{children}</Box>}
        </div>
    );
}

function a11yProps(index: number) {
    return {
        id: `simple-tab-${index}`,
        'aria-controls': `simple-tabpanel-${index}`
    };
}

// tabs option
const tabsOption = [
    {
        label: 'Dashboard',
        icon: <DashboardTwoToneIcon sx={{ fontSize: '1.3rem' }} />
    },
    {
        label: 'Member Register',
        icon: <MemberIcon sx={{ fontSize: '1.3rem' }} />
    },
    {
        label: 'Group Register',
        icon: <GroupIcon sx={{ fontSize: '1.3rem' }} />
    },
    {
        label: 'Project Activity',
        icon: <EventIcon sx={{ fontSize: '1.3rem' }} />
    },
    {
        label: 'Work plan',
        icon: <EventIcon sx={{ fontSize: '1.3rem' }} />
    },

    // {
    //     label: 'Event Register',
    //     icon: <EventIcon sx={{ fontSize: '1.3rem' }} />
    // },
    // {
    //     label: 'Input Register',
    //     icon: <KeyboardIcon sx={{ fontSize: '1.3rem' }} />
    // }
];

const SacpRegisters = () => {
    const theme = useTheme();
    const [value, setValue] = React.useState<number>(0);

    const handleChange = (event: React.SyntheticEvent, newValue: number) => {
        setValue(newValue);
    };

    return (
        <MainCard>
            <Grid container spacing={gridSpacing}>
                <Grid item xs={12}>
                    <Tabs
                        value={value}
                        indicatorColor="primary"
                        textColor="primary"
                        onChange={handleChange}
                        aria-label="simple tabs example"
                        variant="scrollable"
                        sx={{
                            mb: 3,
                            '& a': {
                                minHeight: 'auto',
                                minWidth: 10,
                                py: 1.5,
                                px: 1,
                                mr: 2.25,
                                color: theme.palette.grey[600],
                                display: 'flex',
                                flexDirection: 'row',
                                alignItems: 'center',
                                justifyContent: 'center'
                            },
                            '& a.Mui-selected': {
                                color: theme.palette.primary.main
                            },
                            '& .MuiTabs-indicator': {
                                bottom: 2
                            },
                            '& a > svg': {
                                marginBottom: '0px !important',
                                mr: 1.25
                            }
                        }}
                    >
                        {tabsOption.map((tab, index) => (
                            <Tab key={index} component={Link} to="#" icon={tab.icon}
                                label={tab.label} {...a11yProps(index)} />
                        ))}
                    </Tabs>
                    <TabPanel value={value} index={0}>
                        <Dashboard />
                    </TabPanel>
                    <TabPanel value={value} index={1}>
                        <MemberRegister />
                    </TabPanel>
                    <TabPanel value={value} index={2}>
                        <GroupRegister />
                    </TabPanel>
                    <TabPanel value={value} index={3}>
                        <ProjectActivityMain />
                    </TabPanel>
                    <TabPanel value={value} index={4}>
                        <WorkPlanMain />
                    </TabPanel>
                    {/*<TabPanel value={value} index={4}>
                        <InputRegister />
                    </TabPanel> */}
                    {/* <TabPanel value={value} index={2}>
                        <ReportingPeriodType />
                    </TabPanel> */}
                </Grid>
            </Grid>
        </MainCard>
    );
};

export default SacpRegisters;
